import React, { FunctionComponent, useState } from "react";
import { SectionHeading, SlickSlideContainer } from "../styles";
import PrimaryHeading from "../DesignSystem/PrimaryHeading";
import Slider from "react-slick";

export default function WorkWith(props: any) {
  const SuchiERP = require("../../assets/images/clients/suchierp.svg");
  const GoldenSoftwareSolution = require("../../assets/images/clients/goldensoftwaresolution.svg");
  const aidatamonks = require("../../assets/images/clients/aidatamonks.svg");
  const allesxr = require("../../assets/images/clients/allesxr.svg");
  const arverity = require("../../assets/images/clients/arverity.svg");
  const bharathvadde = require("../../assets/images/clients/bharathvadde.svg");
  const deltalake = require("../../assets/images/clients/deltalake.svg");
  const helpaneedy = require("../../assets/images/clients/helpaneedy.png");
  const hiringdaffodil = require("../../assets/images/clients/hiringdaffodil.svg");
  const hiringsvsit = require("../../assets/images/clients/hiringsvsit.svg");
  const opensoft = require("../../assets/images/clients/opensoft.svg");
  const savrinda = require("../../assets/images/clients/savrinda.svg");
  const suchitaconsulting = require("../../assets/images/clients/suchita-consulting.png");
  const taxcrediloans = require("../../assets/images/clients/taxcrediloans.svg");
  const v4u = require("../../assets/images/clients/v4u.svg");
  const vsitllc = require("../../assets/images/clients/vsitllc.png");
  const BTS = require("../../assets/images/clients/btsitaly.svg");
  const SKSOFT = require("../../assets/images/clients/sksoft.svg");
  const DatalabInfotech = require("../../assets/images/clients/datalab-infotech.svg");
  const SriniSoft = require("../../assets/images/clients/srinisoft.svg");
  const VSITSYSTEMSINC = require("../../assets/images/clients/vsit-systems-inc.svg");
  const Sarvamayi = require("../../assets/images/clients/sarvamayi.svg");
  const LRPOWERSYSTEMS = require("../../assets/images/clients/lrpowersystems.svg");
  const BROWSEPROSPECTSLLC = require("../../assets/images/clients/browseprospectsllc.svg");

  const services = [
    {
      src: LRPOWERSYSTEMS,
      link: "https://www.lrpowersystems.com",
    },
    {
      src: BTS,
      link: "https://www.bharatiyatelugusamithiitaly.org",
    },
    {
      src: Sarvamayi,
      link: "https://www.sarvamayi.com",
    },
    {
      src: SuchiERP,
      link: "https://www.suchierp.com",
    },
    {
      src: GoldenSoftwareSolution,
      link: "https://www.goldensoftsol.com",
    },
    {
      src: aidatamonks,
      link: "https://www.aidatamonks.com",
    },
    {
      src: allesxr,
      link: "https://www.allesxr.com",
    },

    {
      src: deltalake,
      link: "https://www.deltalakesystems.com",
    },
    {
      src: DatalabInfotech,
      link: "https://www.datalabinfotech.com",
    },
    {
      src: SriniSoft,
      link: "https://www.srinisoft.com",
    },

    {
      src: vsitllc,
      link: "https://www.vsitllc.com",
    },
    {
      src: suchitaconsulting,
      link: "https://www.suchitaconsulting.com",
    },

    {
      src: bharathvadde,
      link: "https://www.bharathvadde.com",
    },
    {
      src: opensoft,
      link: "https://www.opensoft.com",
    },

    {
      src: SKSOFT,
      link: "https://www.sksoft.com",
    },

    {
      src: VSITSYSTEMSINC,
      link: "https://www.vsitsystems.com",
    },
    {
      src: helpaneedy,
    },
    {
      src: hiringdaffodil,
    },
    {
      src: arverity,
      link: "https://www.arverity.com",
    },
    {
      src: savrinda,
    },
    {
      src: v4u,
    },

    {
      src: BROWSEPROSPECTSLLC,
    },
    {
      src: hiringsvsit,
    },
    {
      src: taxcrediloans,
    },
  ];

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={"container"}>
      <SlickSlideContainer>
        <PrimaryHeading
          primaryText="Companies We Work with"
          secondaryText=""
          tagline="Our customers are critical to how we think, perform, and grow as we work towards empowering businesses through simplified solutions & services"
        />

        <div className="py-4 list">
          {services.map((item: any, index: number) => {
            return (
              <div className="item" key={index}>
                {item.link ? (
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <img src={item.src} alt="client logo" />
                  </a>
                ) : (
                  <div className="disabled-item" aria-disabled="true">
                    <img src={item.src} alt="client logo" />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </SlickSlideContainer>
    </div>
  );
}
